@tailwind base;

@layer base {

  .no-tailwindcss-base h1 {
    @apply text-4xl;
    @apply font-bold;
  }
  .no-tailwindcss-base h2 {
    @apply text-3xl;
    @apply font-bold;
  }
  .no-tailwindcss-base h3 {
    @apply text-2xl;
    @apply font-bold;
  }
  .no-tailwindcss-base h4 {
    @apply text-xl;
    @apply font-semibold;
  }
  .no-tailwindcss-base h5 {
    @apply text-lg;
    @apply font-semibold;
  }
  .no-tailwindcss-base h6 {
    @apply text-sm;
    @apply font-semibold;
  }

  .no-tailwindcss-base p:empty {
    padding: 10px;
  }

  .no-tailwindcss-base ol,
  .no-tailwindcss-base ul {
      list-style: revert;
      margin: revert;
      padding: revert;
  }
}
@tailwind components;
@tailwind utilities;

.background {
  background: rgb(253, 253, 253) url("../public/img/background.png") repeat-y center / cover;
}

.DraftEditor-editorContainer .public-DraftEditor-content h1 div span{
  font-size: 2em!important;
  font-weight: bolder!important;
  }

  .DraftEditor-editorContainer .public-DraftEditor-content h2 div span{
  font-weight: bolder!important;
  font-size: 1.5em!important;
  }

  .DraftEditor-editorContainer .public-DraftEditor-content h3 div span{
  font-weight: bolder!important;
  font-size: 1.17em!important;

  }

  .DraftEditor-editorContainer .public-DraftEditor-content h4 div span{
  font-size: 1em!important;
  font-weight: bolder!important;
  }

  .DraftEditor-editorContainer .public-DraftEditor-content h5 div span{
  font-size: .83em!important;
  font-weight: bolder!important;
  }

  .DraftEditor-editorContainer .public-DraftEditor-content h6 div span{
  font-size: .67em!important;
  font-weight: bolder!important;
  }

  .public-DraftStyleDefault-block {
    margin: 0!important;
  }

  .rdw-center-aligned-block > div {
    text-align: inherit;
  }

  .rdw-left-aligned-block > div {
    text-align: inherit;
  }

  .rdw-right-aligned-block > div {
    text-align: inherit;
  }
  .rdw-justify-aligned-block > div {
    text-align: inherit;
  }


